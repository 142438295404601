<template>
  <div class="my-chain">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.my-chain {
  height: 100%;
}
</style>